<template>
  <div class="pa-12">
    <user-organism/>
  </div>
</template>

<script>
import UserOrganism from '../components/UserOrganism.vue';

export default {
  name: 'Home',
  components: {
    UserOrganism,
  },
};
</script>
