<template>
  <div>
    <div style="display: none">
      <p>Company Id List</p>
      <p>{{ home.companies }}</p>
    </div>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-btn
        dark
        color="indigo"
        style="width:100%; margin: 2em 0"
        v-if="!scanQrCode"
        @click="scanQrCode = true"
        >
          <v-icon left>
            mdi-qr_code
          </v-icon>
          <span>Scanner le QR code du badge</span>
      </v-btn>
      <div v-if="scanQrCode">
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>

      <v-text-field
        v-model="macAddress"
        :rules="macAddressRules"
        label="Adresse MAC du badge"
        required
      ></v-text-field>

      <v-select
        v-model="companyId"
        :items="companyNameList"
        item-text="name"
        item-value="companyId"
        label="Société"
        required
      ></v-select>

      <v-text-field
        v-model="name"
        :counter="50"
        :rules="nameRules"
        label="Nom"
        required
      ></v-text-field>

      <v-text-field
        v-model="lastname"
        :counter="50"
        :rules="lastnameRules"
        label="Prénom"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-text-field
        v-model="phone"
        :rules="phoneRules"
        label="Téléphone"
      ></v-text-field>

      <v-select
        v-model="role"
        :items="roles"
        :rules="[v => !!v || 'Item is required']"
        label="Role"
        required
      ></v-select>

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
      >
        Validate
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset"
      >
        Reset Form
      </v-btn>

      <v-btn
        color="warning"
        @click="resetValidation"
      >
        Reset Validation
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import gql from 'graphql-tag';
import AddUser from '../graphQL/AddUser.gql';

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    valid: true,
    scanQrCode: false,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v !== '' && v.length <= 50) || 'Name must be less than 10 characters',
    ],
    lastname: '',
    lastnameRules: [
      (v) => !!v || 'Lastname is required',
      (v) => (v && v !== '' && v.length <= 50) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phone: '',
    phoneRules: [
      // (v) => !!v || 'Phone is required',
      // (v) => /.+@.+\..+/.test(v) || 'Phone must be valid',
    ],
    macAddress: '',
    macAddressRules: [
      (v) => !!v || 'A beacon Mac address (D0EB06853B2C) is required',
      (v) => (v && v.length === 12) || 'Mac address should have 12 characters',
    ],
    // -- [guest, habitant, admin, inviter]
    role: null,
    roles: [
      'guest',
      'habitant',
      'admin',
      'inviter',
    ],
    badgeNumber: null,
    companyId: null,
    companiesID: [
      'bag-era-0001',
      'stone-edge-0002',
      'silot-0003',
    ],
    checkbox: false,
  }),
  computed: {
    companyNameList() {
      return this.home.companies || [];
    },
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    home: gql`
      query home ($query: HomeQueryInput) {
          home (query: $query) {
            companies {
              companyId
              name
            }
          }
      }
    `,
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.createUser();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onDecode(evt) {
      this.macAddress = evt;
      this.scanQrCode = false;
    },
    /**
     * @input(String) - D0eb06853B2C
     * @returns(String) - d0:eb:06:85:3b:2c
     */
    convertToMacAddressFormat(macAddr) {
      const macAddrLowerCase = macAddr.toLowerCase();
      const splitedMacAddr = macAddrLowerCase.match(/.{1,2}/g);
      return splitedMacAddr.join(':');
    },
    // -- Add lower case + ':'
    async createUser() {
      const res = await this.$apollo.mutate({
        mutation: AddUser,
        variables: {
          input: {
            phoneNumber: this.phone,
            badgeNumber: {
              uuid: 'toto',
              macAddr: this.convertToMacAddressFormat(this.macAddress),
            },
            name: this.name,
            surname: this.lastname,
            companyId: this.companyId,
            isVip: true,
            role: this.role,
            email: this.email,
          },
        },
      });
      console.log('RES HERE: ', res);
      this.$emit('userFormSent', res);
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
