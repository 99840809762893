<template>
  <div>
    <FilterTable
      :dataTable="getUserWithCompanyName"
      :fieldsToMatch="['email', 'companyName']"
      @filteredMatch="foundedData"
    />
    <!-- USER TABLE -->
    <div>
      <v-data-table
        :headers="headers"
        :items="founded"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon> -->
          <v-icon color="red" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import USERS from '../graphQL/Users.gql';
import HOMES from '../graphQL/Homes.gql';
import FilterTable from './FilterTable.vue';

export default {
  components: {
    FilterTable,
  },
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msg: 'Hi there!',
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Entreprise', value: 'companyName' },
        { text: 'Role', value: 'role' },
        {
          text: 'Badge number',
          align: 'start',
          sortable: false,
          value: 'badgeNumber.macAddr',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      founded: [],
    };
  },
  computed: {
    getUserWithCompanyName() {
      const userWithCompanyName = [];
      if (this.users && this.homes) {
        this.users.forEach((user) => {
          const company = this.homes[0].companies.filter((c) => c.companyId === user.companyId);
          const userComplete = user;
          userComplete.companyName = company && company[0] ? company[0].name : 'unknown';
          userWithCompanyName.push(userComplete);
        });
      }
      return userWithCompanyName;
    },
  },
  apollo: {
    users: USERS,
    homes: HOMES,
  },
  watch: {
    refresh() {
      this.refreshData();
    },
    getUserWithCompanyName(nVal) {
      this.founded = nVal;
    },
  },
  methods: {
    refreshData() {
      this.$apollo.queries.users.refetch();
    },
    async deleteItem(item) {
      const userId = item._id;
      await this.$apollo.mutate({
        mutation: gql`
          mutation deleteOneUser ($query: UserQueryInput!) {
            deleteOneUser (query: $query) {
              _id
            }
          }`,
        variables: {
          query: {
            _id: userId,
          },
        },
      });
      this.refreshData();
    },
    foundedData(el) {
      this.founded = el;
    },
  },
};
</script>

<style scoped>
.super-class {
  background: #4b79b6;
}
</style>
